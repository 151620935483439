import React from "react";
import img1 from "../../../assets/img/trading/4/1.png";
import classes from "./Four.module.scss";
import { useTranslation } from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.four, "mt mb"].join(" ")}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fourRow, ""].join(" ")}>
            <div className={[classes.fourRowLeft, ""].join(" ")}>
              <img src={img1} alt="Third-party tools" />
            </div>
            <div className={[classes.fourRowRight, ""].join(" ")}>
              <h2 className={[classes.fourTitle, "font-45"].join(" ")}>
                {t('trading_tools_title')}
              </h2>
              <h3 className={[classes.fourSubtitle, "font-25"].join(" ")}>
                {t('trading_tools_subtitle')}
              </h3>
              <div className={[classes.fourItem, ""].join(" ")}>
                <h4 className={[classes.fourItemTitle, "font-20"].join(" ")}>
                  {t('trading_tools_card_01_title')}
                </h4>
                <p className={[classes.fourItemSubtitle, "font-20"].join(" ")}>
                  {t('trading_tools_card_01_text')}
                </p>
              </div>
              <div className={[classes.fourItem, ""].join(" ")}>
                <h4 className={[classes.fourItemTitle, "font-20"].join(" ")}>
                  {t('trading_tools_card_02_title')}
                </h4>
                <p className={[classes.fourItemSubtitle, "font-20"].join(" ")}>
                  {t('trading_tools_card_02_text')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
