import React from "react";
import classes from "./Four.module.scss";
import { useTranslation } from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.four, "mb"].join(" ")}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.fourTitle, "font-45"].join(" ")}>
            {t('education_margin_title')}
          </h2>
          <div className={[classes.fourRow, ""].join(" ")}>
            <div className={[classes.fourRowLeft, ""].join(" ")}>
              <h3 className={[classes.fourRowLeftTitle, "font-20"].join(" ")}>
                {t('education_margin_subtitle_01')}
              </h3>
              <p className={[classes.fourRowLeftText, "font-20"].join(" ")}>
                {t('education_margin_text_01')}
              </p>
              <p className={[classes.fourRowLeftText, "font-20"].join(" ")}>
                {t('education_margin_text_02')}
              </p>
            </div>
            <div className={[classes.fourRowRight, ""].join(" ")}>
              <h3 className={[classes.fourRowRightTitle, "font-20"].join(" ")}>
                {t('education_margin_subtitle_02')}
              </h3>
              <p className={[classes.fourRowRightText, "font-20"].join(" ")}>
                {t('education_margin_text_03')}
              </p>
              <p className={[classes.fourRowRightText, "font-20"].join(" ")}>
                {t('education_margin_text_04')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
