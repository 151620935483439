import React, { useRef } from "react";
import classes from "./Three.module.scss";
import $ from "jquery";
import { Trans, useTranslation } from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  return (
    <div className={classes.three}>
      <div className={[classes.threeBody, "mt mb"].join(" ")}>
        <div className="container">
          <h2 className={[classes.threeTitle, "font-45"].join(" ")}>
            {t('home_why_choose_title')}
          </h2>
          <div className={classes.threeRow}>
            <div
              className={classes.threeRowColumn}
              onMouseLeave={() => $(ref1.current).slideUp(300)}
              onMouseEnter={() => $(ref1.current).slideDown(300)}
            >
              <div className={classes.threeRowColumnTop}>
                <h2 className={[classes.threeRowColumnTopTitle, "font-20"].join(" ")}>
                  <Trans>
                    {t('home_why_choose_card_01_title')}
                  </Trans>
                </h2>
              </div>
              <div
                className={classes.threeRowColumnContent}
                ref={ref1}
              >
                <p className={[classes.threeRowColumnContentText, "font-18"].join(" ")}>
                  {t('home_why_choose_card_01_text')}
                </p>
              </div>
            </div>
            <div
              className={classes.threeRowColumn}
              onMouseLeave={() => $(ref2.current).slideUp(300)}
              onMouseEnter={() => $(ref2.current).slideDown(300)}
            >
              <div className={classes.threeRowColumnTop}>
                <h2 className={[classes.threeRowColumnTopTitle, "font-20"].join(" ")}>
                  <Trans>
                    {t('home_why_choose_card_02_title')}
                  </Trans>
                </h2>
              </div>
              <div
                className={classes.threeRowColumnContent}
                ref={ref2}
              >
                <p className={[classes.threeRowColumnContentText, "font-18"].join(" ")}>
                  {t('home_why_choose_card_02_text')}
                </p>
              </div>
            </div>
            <div
              className={classes.threeRowColumn}
              onMouseLeave={() => $(ref3.current).slideUp(300)}
              onMouseEnter={() => $(ref3.current).slideDown(300)}
            >
              <div className={classes.threeRowColumnTop}>
                <h2 className={[classes.threeRowColumnTopTitle, "font-20"].join(" ")}>
                  <Trans>
                    {t('home_why_choose_card_03_title')}
                  </Trans>
                </h2>
              </div>
              <div
                className={classes.threeRowColumnContent}
                ref={ref3}
              >
                <p className={[classes.threeRowColumnContentText, "font-18"].join(" ")}>
                  {t('home_why_choose_card_03_text')}
                </p>
              </div>
            </div>
            <div
              className={classes.threeRowColumn}
              onMouseLeave={() => $(ref4.current).slideUp(300)}
              onMouseEnter={() => $(ref4.current).slideDown(300)}
            >
              <div className={classes.threeRowColumnTop}>
                <h2 className={[classes.threeRowColumnTopTitle, "font-20"].join(" ")}>
                  <Trans>
                    {t('home_why_choose_card_04_title')}
                  </Trans>
                </h2>
              </div>
              <div
                className={classes.threeRowColumnContent}
                ref={ref4}
              >
                <p className={[classes.threeRowColumnContentText, "font-18"].join(" ")}>
                  {t('home_why_choose_card_04_text')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
