import { useEffect, useState, useRef } from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/AccountsPage/Banner/Banner";
import WhyTrade from "../../components/AccountsPage/WhyTrade/WhyTrade";
import Categories from "../../components/AccountsPage/Categories/Categories";
import HowToChange from "../../components/AccountsPage/HowToChange/HowToChange";
import ExpertSupport from "../../components/AccountsPage/ExpertSupport/ExpertSupport";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AccountsPage = () => {
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
    }
  }, [width]);
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <div className="anim-block" ref={col1}>
        <WhyTrade />
      </div>
      <div className="anim-block" ref={col2}>
        <Categories />
      </div>
      <div className="anim-block" ref={col3}>
        <HowToChange />
      </div>
      <div className="anim-block" ref={col4}>
        <ExpertSupport />
      </div>
      <Footer />
    </div>
  );
};

export default AccountsPage;
