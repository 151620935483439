import React, { useEffect, useState } from "react";
import classes from './Categories.module.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Trans, useTranslation } from "react-i18next";
import info_icon from '../../../assets/img/AccountsPage/info_icon.svg';
import Button from "../../GlobalComponents/Button/Button";

const cards = [
    {
        title: 'Classic',
        text_01: 'accounts_categories_01_text_01',
        text_02: 'accounts_categories_01_text_02',
        list: [ 
            {
                li: 'accounts_categories_01_li_00',
                hidden: false,
            },
            {
                li: 'accounts_categories_01_li_01',
                hidden: false,
            },
            {
                li: 'accounts_categories_01_li_02',
                hidden: false,
            },
            {
                li: 'accounts_categories_01_li_03',
                hidden: false,
            },
            {
                li: 'accounts_categories_01_li_04',
                hidden: true,
            },
            {
                li: 'accounts_categories_01_li_05',
                hidden: true,
            },
            {
                li: 'accounts_categories_01_li_06',
                hidden: true,
            },
        ],
    },
    {
        title: 'Platinum',
        text_01: 'accounts_categories_02_text_01',
        text_02: 'accounts_categories_02_text_02',
        list: [
            {
                li: 'accounts_categories_02_li_01',
                hidden: false,
            },
            {
                li: 'accounts_categories_02_li_02',
                hidden: false,
            },
            {
                li: 'accounts_categories_02_li_03',
                hidden: false,
            },
            {
                li: 'accounts_categories_02_li_04',
                hidden: false,
            },
            {
                li: 'accounts_categories_02_li_05',
                hidden: true,
            },
            {
                li: 'accounts_categories_02_li_06',
                hidden: true,
            },
        ],
    },
    {
        title: 'VIP',
        text_01: 'accounts_categories_03_text_01',
        text_02: 'accounts_categories_03_text_02',
        list: [
            {
                li: 'accounts_categories_03_li_01',
                hidden: false,
            },
            {
                li: 'accounts_categories_03_li_02',
                hidden: false,
            },
            {
                li: 'accounts_categories_03_li_03',
                hidden: false,
            },
            {
                li: 'accounts_categories_03_li_04',
                hidden: false,
            },
            {
                li: 'accounts_categories_03_li_05',
                hidden: false,
            },
            {
                li: 'accounts_categories_03_li_06',
                hidden: false,
            },
            {
                li: 'accounts_categories_03_li_07',
                hidden: true,
            },
        ],
    },
    {
        title: 'Royal',
        text_01: 'accounts_categories_04_text_01',
        text_02: 'accounts_categories_04_text_02',
        list: [
            {
                li: 'accounts_categories_04_li_01',
                hidden: false,
            },
            {
                li: 'accounts_categories_04_li_02',
                hidden: false,
            },
            {
                li: 'accounts_categories_04_li_03',
                hidden: false,
            },
            {
                li: 'accounts_categories_04_li_04',
                hidden: false,
            },
            {
                li: 'accounts_categories_04_li_05',
                hidden: false,
            },
            {
                li: 'accounts_categories_04_li_06',
                hidden: false,
            },
            {
                li: 'accounts_categories_04_li_07',
                hidden: false,
            },
            {
                li: 'accounts_categories_04_li_08',
                hidden: false,
            },
        ],
    },
];

const Categories = () => {
    const { t } = useTranslation();
    const [ domLoaded, setDomLoaded ] = useState(false);
    useEffect(() => {
        setDomLoaded(true);
    }, []);
    return (
        <>
            {domLoaded &&
                <section className={classes.categories}>
                    <div className="container">
                        <h2 className={[classes.title, 'font-45'].join(' ')}>
                            {t('accounts_categories_title')}
                        </h2>
                        <h3 className={[classes.subtitle, 'font-25'].join(' ')}>
                            {t('accounts_categories_subtitle')}
                        </h3>
                    </div>
                    <div className={[classes.container, 'container'].join(' ')}>
                        <div className={classes.swiperWr}>
                            <Swiper 
                                modules={[Pagination]}
                                className="accountsCategoriesSwiper"
                                spaceBetween={20}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                breakpoints={{
                                    768: {
                                        spaceBetween: 40,
                                        slidesPerView: 2,
                                    },
                                    1220: {
                                        spaceBetween: 40,
                                        slidesPerView: 3,
                                    },
                                    1420: {
                                        spaceBetween: 30,
                                        slidesPerView: 3,
                                    },
                                    1650: {
                                        spaceBetween: 20,
                                        slidesPerView: 4,
                                    },
                                }}
                            >
                                {cards.map((card, index) =>
                                    <SwiperSlide key={index} className="accountsCategoriesSwiperSlide">
                                        <div className={classes.card}>
                                            <div className={classes.content}>
                                                <h4 className={[classes.cardTitle, 'font-30'].join(' ')}>
                                                    {card.title}
                                                </h4>
                                                <p className={classes.cardText_01}>
                                                    <Trans>
                                                        {t(card.text_01)}
                                                    </Trans>
                                                </p>
                                                <p className={classes.cardText_02}>
                                                    {t(card.text_02)}
                                                </p>
                                                <ul>
                                                    {card.list.map((li, liIndex) =>
                                                        <li key={liIndex} className={`${li.hidden && classes.liHidden}`}>
                                                            {t(li.li)} 
                                                            {li.hidden && 
                                                                <span className={classes.infoIconWr}>
                                                                    <img className={classes.infoIcon} src={info_icon} alt=''/>
                                                                </span>
                                                            }
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            <div className={classes.btn}>
                                                <Button>
                                                    {t('btn_open_account')}
                                                </Button>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default Categories;
