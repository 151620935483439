import React from "react";
import classes from './HowToChange.module.scss';
import how_to_change from '../../../assets/img/AccountsPage/how_to_change.png';
import { useTranslation } from "react-i18next";

const HowToChange = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.howToChange}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('accounts_how_change_title')}
                </h2>
                <div className={[classes.text, 'font-20'].join(' ')}>
                    <p>
                        {t('accounts_how_change_text_01')}
                    </p>
                    <p>
                        {t('accounts_how_change_text_02')}
                    </p>
                    <p>
                        {t('accounts_how_change_text_03')}
                    </p>
                </div>
                <img className={classes.image} src={how_to_change} alt=''/>
            </div>
        </section>
    );
};

export default HowToChange;
