import React, { useEffect, useState } from "react";
import classes from "./WhyTrade.module.scss";
import icon_01 from "../../../assets/img/education/3/1.png";
import icon_02 from "../../../assets/img/education/3/2.png";
import icon_03 from "../../../assets/img/education/3/3.png";
import icon_04 from "../../../assets/img/education/3/4.png";
import icon_h_01 from "../../../assets/img/education/3/11.png";
import icon_h_02 from "../../../assets/img/education/3/22.png";
import icon_h_03 from "../../../assets/img/education/3/33.png";
import icon_h_04 from "../../../assets/img/education/3/44.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Trans, useTranslation } from "react-i18next";

const cards = [
  {
    icon: icon_01,
    icon_h: icon_h_01,
    title: "education_tr_tools_card_01_title",
    text: "education_tr_tools_card_01_text",
  },
  {
    icon: icon_02,
    icon_h: icon_h_02,
    title: "education_tr_tools_card_02_title",
    text: "education_tr_tools_card_02_text",
  },
  {
    icon: icon_03,
    icon_h: icon_h_03,
    title: "education_tr_tools_card_03_title",
    text: "education_tr_tools_card_03_text",
  },
  {
    icon: icon_04,
    icon_h: icon_h_04,
    title: "education_tr_tools_card_04_title",
    text: "education_tr_tools_card_04_text",
  },
];

const WhyTrade = () => {
  const { t } = useTranslation();
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <>
      {domLoaded && (
        <section>
          <div className={[classes.whyTrade, "mb"].join(" ")}>
            <div className="container">
              <h2 className={[classes.title, "font-45"].join(" ")}>
                {t('education_tr_tools_title')}
              </h2>
            </div>
            <div className={[classes.container, "container"].join(" ")}>
              <div className={classes.swiperWr}>
                <Swiper
                  modules={[Pagination]}
                  className="accountsWhyTradeSwiper"
                  spaceBetween={40}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  breakpoints={{
                    768: {
                      slidesPerView: 2,
                    },
                    990: {
                      slidesPerView: 3,
                    },
                    1220: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {cards.map((card, index) => (
                    <SwiperSlide
                      key={index}
                      className="accountsWhyTradeSwiperSlide"
                    >
                      <div className={classes.card}>
                        <img
                          className={classes.cardIcon}
                          src={card.icon}
                          alt=""
                        />
                        <img
                          className={classes.cardIconHover}
                          src={card.icon_h}
                          alt=""
                        />
                        <h4
                          className={[classes.cardTitle, "font-20"].join(" ")}
                        >
                          <Trans>{t(card.title)}</Trans>
                        </h4>
                        <p className={[classes.cardText, "font-18"].join(" ")}>
                          <Trans>{t(card.text)}</Trans>
                        </p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default WhyTrade;
