import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import money_01 from '../../../assets/img/ProductsPage/Banner/money_01.png';
import money_02 from '../../../assets/img/ProductsPage/Banner/money_02.png';
import money_03 from '../../../assets/img/ProductsPage/Banner/money_03.png';
import phone from '../../../assets/img/ProductsPage/Banner/phone.png';
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const { t } = useTranslation();
    const bannerPhoneRef = useRef(null);
    const bannerMoneyRef_01 = useRef(null);
    const bannerMoneyRef_02 = useRef(null);
    const bannerMoneyRef_03 = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.to(bannerMoneyRef_01.current, {
                    x: 0,
                    y: 0,
                    opacity: 1,
                    scale: 1,
                    rotate: 0,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.to(bannerMoneyRef_02.current, {
                    x: 0,
                    y: 0,
                    opacity: 1,
                    scale: 1,
                    rotate: 0,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.to(bannerMoneyRef_03.current, {
                    x: 0,
                    y: 0,
                    opacity: 1,
                    scale: 1,
                    rotate: 0,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.to(bannerPhoneRef.current, {
                    x: 0,
                    y: 0,
                    scale: 1,
                    duration: 1.5,
                    // delay: 0.5,
                });
            }
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={classes.title}>
                        {t('products_banner_title')}
                    </h1>
                    <p className={classes.text}>
                        {t('products_banner_subtitle')}
                    </p>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn_start_inv')}
                        </Button>
                    </div>
                </div>
                <img ref={bannerPhoneRef} className={classes.phone} src={phone} alt=''/>
                <img ref={bannerMoneyRef_01} className={classes.money_01} src={money_01} alt=''/>
                <img ref={bannerMoneyRef_02} className={classes.money_02} src={money_02} alt=''/>
                <img ref={bannerMoneyRef_03} className={classes.money_03} src={money_03} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
