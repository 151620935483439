import Button from "../../GlobalComponents/Button/Button";
import React from "react";
import classes from "./Five.module.scss";
import img1 from "../../../assets/img/trading/5/1.png";
import { useTranslation } from "react-i18next";

const Five = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.five, ""].join(" ")}>
      <div className={[classes.fiveBody, ""].join(" ")}>
        <div className={[classes.fiveWrapper, ""].join(" ")}>
          <div className="container">
            <h2 className={[classes.fiveTitle, "font-45"].join(" ")}>
              {t('trading_ready_title')}
            </h2>
            <p className={[classes.fiveSubtile, "font-25"].join(" ")}>
              {t('trading_ready_text')}
            </p>
            <div className={[classes.fiveBtn, ""].join(" ")}>
              <Button white>
                {t('btn_open_account')}
              </Button>
            </div>
          </div>
          <div className={[classes.fiveAbsImg, ""].join(" ")}>
            <img quality={100} src={img1} alt={"Ready to start"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
