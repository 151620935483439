import Button from "../../GlobalComponents/Button/Button";
import React from "react";
import classes from "./Three.module.scss";
import img1 from "../../../assets/img/trading/3/1.png";
import { useTranslation } from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.three, ""].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeWrapper, ""].join(" ")}>
            <div className={[classes.threeWrapperInfo, ""].join(" ")}>
              <h2 className={[classes.threeTitle, "font-45"].join(" ")}>
                {t('trading_platform_title')}
              </h2>
              <h3 className={[classes.threeSubtitle, "font-25"].join(" ")}>
                {t('trading_platform_subtitle')}
              </h3>
              <div className={[classes.threeItem, ""].join(" ")}>
                <h4 className={[classes.threeItemTitle, "font-20"].join(" ")}>
                  {t('trading_platform_card_01_title')}
                </h4>
                <p className={[classes.threeItemSubtitle, "font-20"].join(" ")}>
                  {t('trading_platform_card_01_text')}
                </p>
              </div>
              <div className={[classes.threeItem, ""].join(" ")}>
                <h4 className={[classes.threeItemTitle, "font-20"].join(" ")}>
                  {t('trading_platform_card_02_title')}
                </h4>
                <p className={[classes.threeItemSubtitle, "font-20"].join(" ")}>
                  {t('trading_platform_card_02_text')}
                </p>
              </div>
              <div className={[classes.threeItem, ""].join(" ")}>
                <h4 className={[classes.threeItemTitle, "font-20"].join(" ")}>
                  {t('trading_platform_card_03_title')}
                </h4>
                <p className={[classes.threeItemSubtitle, "font-20"].join(" ")}>
                  {t('trading_platform_card_03_text')}
                </p>
              </div>
            </div>

            <div className={[classes.threeImg, ""].join(" ")}>
              <img src={img1} alt="our platform" />
            </div>
          </div>
          <div className={[classes.threeBtn, ""].join(" ")}>
            <Button>
              {t('btn_open_account')}
            </Button>
          </div>
          <div className={[classes.threeMobImg, ""].join(" ")}>
            <img src={img1} alt="our platform mobile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
