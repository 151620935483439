import Button from "../../GlobalComponents/Button/Button";
import React, { useState, useRef } from "react";
import classes from "./Six.module.scss";
import $ from "jquery";
import img1 from "../../../assets/img/main/4/1.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Six = () => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  return (
    <div className={[classes.six, "no-select"].join(" ")}>
      <div className={classes.sixBody}>
        <div className="container">
          <div className={classes.sixRow}>
            <div className={classes.sixRowLeft}>
              <div
                className={
                  count === 1
                    ? [classes.sixRowLeftTab, classes.active].join(" ")
                    : classes.sixRowLeftTab
                }
              >
                <h3
                  onClick={() => {
                    if (count === 1) {
                      setCount(0);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    } else {
                      setCount(1);
                      $(ref1.current).slideDown(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    }
                  }}
                  className={[classes.sixRowLeftTabTitle, "font-20"].join(" ")}
                >
                  {t('home_tabs_01_title')}
                </h3>
                <div
                  className={classes.sixRowLeftTabContent}
                  ref={ref1}
                >
                  <h4 className={[classes.sixRowLeftTabContentTitle, "font-18"].join(" ")}>
                    {t('home_tabs_01_subtitle')}
                  </h4>
                  <p className={[classes.sixRowLeftTabContentSubtitle, "font-18"].join(" ")}>
                    {t('home_tabs_01_text')}
                  </p>
                  <p className={[classes.sixRowLeftTabContentText, "font-18"].join(" ")}>
                    {t('home_tabs_01_more_about')}&nbsp;
                    <Link to="/accounts">
                      {t('btn_managed_portfolios')}
                    </Link>
                  </p>
                </div>
              </div>
              <div
                className={
                  count === 2
                    ? [classes.sixRowLeftTab, classes.active].join(" ")
                    : classes.sixRowLeftTab
                }
              >
                <h3
                  className={[classes.sixRowLeftTabTitle, "font-20"].join(" ")}
                  onClick={() => {
                    if (count === 2) {
                      setCount(0);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    } else {
                      setCount(2);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideDown(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    }
                  }}
                >
                  {t('home_tabs_02_title')}
                </h3>
                <div
                  className={classes.sixRowLeftTabContent}
                  ref={ref2}
                >
                  <h4 className={[classes.sixRowLeftTabContentTitle, "font-18"].join(" ")}>
                    {t('home_tabs_02_text')}
                  </h4>

                  <Link to="/trading">
                    <Button nolink>
                      {t('btn_discover_more')}
                    </Button>
                  </Link>
                </div>
              </div>
              <div
                className={
                  count === 3
                    ? [classes.sixRowLeftTab, classes.active].join(" ")
                    : classes.sixRowLeftTab
                }
              >
                <h3
                  className={[classes.sixRowLeftTabTitle, "font-20"].join(" ")}
                  onClick={() => {
                    if (count === 3) {
                      setCount(0);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    } else {
                      setCount(3);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideDown(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    }
                  }}
                >
                  {t('home_tabs_03_title')}
                </h3>
                <div
                  className={classes.sixRowLeftTabContent}
                  ref={ref3}
                >
                  <h4 className={[classes.sixRowLeftTabContentTitle, "font-18"].join(" ")}>
                    {t('home_tabs_03_subtitle')}
                  </h4>
                  <p className={[classes.sixRowLeftTabContentSubtitle, "font-18"].join(" ")}>
                    {t('home_tabs_03_text')}
                  </p>
                </div>
              </div>
              <div
                className={
                  count === 4
                    ? [classes.sixRowLeftTab, classes.active].join(" ")
                    : classes.sixRowLeftTab
                }
              >
                <h3
                  className={[classes.sixRowLeftTabTitle, "font-20"].join(" ")}
                  onClick={() => {
                    if (count === 4) {
                      setCount(0);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    } else {
                      setCount(4);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideDown(300);
                      $(ref5.current).slideUp(300);
                    }
                  }}
                >
                  {t('home_tabs_04_title')}
                </h3>
                <div
                  className={classes.sixRowLeftTabContent}
                  ref={ref4}
                >
                  <h4 className={[classes.sixRowLeftTabContentTitle, "font-18"].join(" ")}>
                    {t('home_tabs_04_subtitle')}
                  </h4>
                  <p className={[classes.sixRowLeftTabContentSubtitle, "font-18"].join(" ")}>
                    {t('home_tabs_04_text')}
                  </p>
                  <Link to="/products">
                    <Button nolink>
                      {t('btn_see_more_prices')}
                    </Button>
                  </Link>
                </div>
              </div>
              <div
                className={
                  count === 5
                    ? [classes.sixRowLeftTab, classes.active].join(" ")
                    : classes.sixRowLeftTab
                }
              >
                <h3
                  className={[classes.sixRowLeftTabTitle, "font-20"].join(" ")}
                  onClick={() => {
                    if (count === 5) {
                      setCount(0);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    } else {
                      setCount(5);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideDown(300);
                    }
                  }}
                >
                  {t('home_tabs_05_title')}
                </h3>
                <div
                  className={classes.sixRowLeftTabContent}
                  ref={ref5}
                >
                  <h4 className={[classes.sixRowLeftTabContentTitle, "font-18"].join(" ")}>
                    {t('home_tabs_05_subtitle')}
                  </h4>
                  <p className={[classes.sixRowLeftTabContentSubtitle, "font-18"].join(" ")}>
                    {t('home_tabs_05_text')}
                  </p>
                  <Link to="/about">
                    <Button nolink>
                      {t('btn_read_more')}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className={classes.sixRowRight}>
              <img quality={100} src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Six;
