import React from "react";
import classes from './BondLanding.module.scss';
import image from '../../../assets/img/ProductsPage/bond_landing.png';
import { Trans, useTranslation } from "react-i18next";

const BondLanding = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.bondLanding}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('products_bond_title')}
                </h2>
                <p className={classes.text}>
                    <Trans>
                        {t('products_bond_text')}
                    </Trans>
                </p>
                <div className={classes.tableWr}>
                    <table>
                        <tbody>
                            <tr>
                                <td>{t('products_bond_col_01')}</td>
                                <td>{t('products_bond_col_02')}</td>
                            </tr>
                            <tr>
                                <td>{t('products_bond_row_01')}</td>
                                <td>95%</td>
                            </tr>
                            <tr>
                                <td>{t('products_bond_row_02')}</td>
                                <td>90%</td>
                            </tr>
                            <tr>
                                <td>{t('products_bond_row_03')}</td>
                                <td>80%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default BondLanding;
