import React from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/ProductsPage/Banner/Banner";
import ProductsFor from "../../components/ProductsPage/ProductsFor/ProductsFor";
import Prices from "../../components/ProductsPage/Prices/Prices";
import LandingRates from "../../components/ProductsPage/LandingRates/LandingRates";
import BondLanding from "../../components/ProductsPage/BondLanding/BondLanding";
import ExpertSupport from "../../components/ProductsPage/ExpertSupport/ExpertSupport";

const ProductsPage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <ProductsFor />
      <Prices />
      <LandingRates />
      <BondLanding />
      <ExpertSupport />
      <Footer />
    </div>
  );
};

export default ProductsPage;
