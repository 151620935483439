import React from "react";
import classes from './OurTop.module.scss';
import brand_dollar from '../../../assets/img/AboutPage/brands/brand_dollar.png';
import brand_euro from '../../../assets/img/AboutPage/brands/brand_euro.png';
import { useTranslation } from "react-i18next";

const OurTop = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.weConnect}>
                <img src={brand_dollar} className={[classes.brand_dollar, classes.brand].join(' ')} alt=''/>
                <img src={brand_euro} className={[classes.brand_euro, classes.brand].join(' ')} alt=''/>
                <div className={classes.weConnectBody}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('about_our_top_title')}
                    </h2>
                    <div className={[classes.content, 'font-20'].join(' ')}>
                        <div className={classes.col}>
                            <div className={classes.block}>
                                <div className={classes.subtitle}>
                                    {t('about_our_top_card_01_title')}
                                </div>
                                <div className={classes.text}>
                                    {t('about_our_top_card_01_text')}
                                </div>
                            </div>
                            <div className={classes.block}>
                                <div className={classes.subtitle}>
                                    {t('about_our_top_card_02_title')}
                                </div>
                                <div className={classes.text}>
                                    {t('about_our_top_card_02_text')}
                                </div>
                            </div>
                        </div>
                        <div className={classes.col}>
                            <div className={classes.block}>
                                <div className={classes.subtitle}>
                                    {t('about_our_top_card_03_title')}
                                </div>
                                <div className={classes.text}>
                                    {t('about_our_top_card_03_text')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurTop;
