import React from "react";
import classes from "./WhatMakes.module.scss";
import img1 from "../../../assets/img/education/5/1.png";
import { useTranslation } from "react-i18next";

const WhatMakes = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.what, "mt"].join(" ")}>
      <div className={[classes.whatBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.whatRow, ""].join(" ")}>
            <div className={[classes.whatRowLeft, ""].join(" ")}>
              <img src={img1} alt={"What makes a CFD trader successful?"} />
            </div>
            <div className={[classes.whatRowRight, ""].join(" ")}>
              <h2 className={[classes.whatRowRightTitle, "font-45"].join(" ")}>
                {t('education_what_makes_title')}
              </h2>
              <p className={[classes.whatRowRightText, "font-20"].join(" ")}>
                {t('education_what_makes_text_01')}
              </p>
              <p className={[classes.whatRowRightText, "font-20"].join(" ")}>
                {t('education_what_makes_text_02')}
              </p>
              <p className={[classes.whatRowRightText, "font-20"].join(" ")}>
                {t('education_what_makes_text_03')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatMakes;
