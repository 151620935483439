import Button from "../../GlobalComponents/Button/Button";
import React from "react";
import classes from './Banner.module.scss';
import image from '../../../assets/img/AccountsPage/banner.png';
import { Trans, useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <h1 className={[classes.title, 'font-45'].join(' ')}>
                    {t('accounts_banner_title')}
                </h1>
                <p className={[classes.text, 'font-25'].join(' ')}>
                    <Trans>
                        {t('accounts_banner_text')}
                    </Trans>
                </p>
                <div className={classes.btn}>
                    <Button>
                        {t('btn_open_account')}
                    </Button>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
