import React from "react";
import classes from './WorkingTogether.module.scss';
import image from '../../../assets/img/AboutPage/working_together.png';
import { useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';

const WorkingTogether = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.workingTogether}>
            <div className="container">
                <div className={classes.workingTogetherBody}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-40'].join(' ')}>
                            {t('about_wrk_together_title')}
                        </h2>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            {t('about_wrk_together_text')}
                        </p>
                        <div className={classes.btn}>
                            <Button white>
                                {t('btn_open_account')}
                            </Button>
                        </div>
                    </div>
                    <img className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default WorkingTogether;
