import Button from "../../GlobalComponents/Button/Button";
import React from "react";
import classes from "./First.module.scss";
import circleImg from "../../../assets/img/education/1/1.png";
import notebookImg from "../../../assets/img/education/1/2.png";
import bgImg from "../../../assets/img/education/1/3.png";
import mobImg from "../../../assets/img/education/1/mob.png";
import { useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.first, "mb"].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.firstWrapper, ""].join(" ")}>
            <div className={[classes.firstInfo, ""].join(" ")}>
              <h2 className={[classes.firstInfoTitle, "font-45"].join(" ")}>
                {t('education_banner_title')}
              </h2>
              <p className={[classes.firstInfoSubtitle, "font-20"].join(" ")}>
                {t('education_banner_text')}
              </p>
              <div className={[classes.firstInfoBtn, ""].join(" ")}>
                <Button white>
                  {t('btn_open_account')}
                </Button>
              </div>
            </div>
            <div className={[classes.firstMob, ""].join(" ")}>
              <img src={mobImg} alt="mobile" />
            </div>

            <div className={[classes.firstCircle, ""].join(" ")}>
              <img src={circleImg} alt="circle" />
            </div>
            <div className={[classes.firstNotebook, ""].join(" ")}>
              <img src={notebookImg} alt="notebook" />
            </div>
            <div className={[classes.firstBg, ""].join(" ")}>
              <img src={bgImg} alt="bg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
