import React, { useEffect, useRef } from "react";
import classes from './MarketsNever.module.scss';
import image from '../../../assets/img/AboutPage/markets_never.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const MarketsNever = () => {
    const { t } = useTranslation();
    const marketsNeverImgRef = useRef(null);
    const marketsNeverBodyRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(marketsNeverImgRef.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: marketsNeverBodyRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="container">
            <div ref={marketsNeverBodyRef} className={classes.marketsNever}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        <Trans>
                            {t('about_markets_title')}
                        </Trans>
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            {t('about_markets_text_01')}
                        </p>
                        <p>
                            {t('about_markets_text_02')}
                        </p>
                        <p>
                            {t('about_markets_text_03')}
                        </p>
                        <p>
                            {t('about_markets_text_04')}
                        </p>
                        <p>
                            {t('about_markets_text_05')}
                        </p>
                    </div>
                </div>
                <img ref={marketsNeverImgRef} className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default MarketsNever;
