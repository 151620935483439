import Button from "../../GlobalComponents/Button/Button";
import React from "react";
import classes from "./First.module.scss";
import img1 from "../../../assets/img/trading/1/1.png";
import img2 from "../../../assets/img/trading/1/2.png";
import { useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.first, "mb"].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.firstRow, ""].join(" ")}>
            <div className={[classes.firstRowImgAbs, ""].join(" ")}>
              <img src={img1} alt={"circle"} />
            </div>

            <div className={[classes.firstRowLeft, ""].join(" ")}>
              <h2 className={[classes.firstRowLeftTitle, "font-45"].join(" ")}>
                {t('trading_banner_title')}
              </h2>
              <p className={[classes.firstRowLeftText, "font-25"].join(" ")}>
                {t('trading_banner_text')}
              </p>
              <div className={[classes.firstRowLeftBtn, ""].join(" ")}>
                <Button>
                  {t('btn_open_account')}
                </Button>
              </div>
            </div>
            <div className={[classes.firstRowRight, ""].join(" ")}>
              <img
                quality={100}
                src={img2}
                alt={"Skylar Trade Platform"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
