import Button from "../../GlobalComponents/Button/Button";
import React, { useState, useEffect, useRef } from "react";
import classes from "./First.module.scss";
import phoneImg from "../../../assets/img/main/1/1.png";
import chartImg from "../../../assets/img/main/1/2.png";
import m1Img from "../../../assets/img/main/1/m1.png";
import m2Img from "../../../assets/img/main/1/m2.png";
import m3Img from "../../../assets/img/main/1/m3.png";
import m4Img from "../../../assets/img/main/1/m4.png";
import m5Img from "../../../assets/img/main/1/m5.png";
import mob1Img from "../../../assets/img/main/1/mob1.png";
import mob2Img from "../../../assets/img/main/1/mob2.png";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  const [hover, setHover] = useState(false);
  const phoneRef = useRef(null);
  const m1Ref = useRef(null);
  const m2Ref = useRef(null);
  const m3Ref = useRef(null);
  const m4Ref = useRef(null);
  const m5Ref = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(phoneRef.current, {
        scale: 1,
        y: 0,
        duration: 2,
        delay: 0.5,
      });
      gsap.timeline().to(m1Ref.current, {
        opacity: 1,
        y: 0,
        x: 0,
        duration: 2,
        rotate: 0,
        delay: 0.5,
      });
      gsap.timeline().to(m2Ref.current, {
        opacity: 1,
        y: 0,
        x: 0,
        rotate: 0,
        duration: 2,
        delay: 0.5,
      });
      gsap.timeline().to(m3Ref.current, {
        opacity: 1,
        y: 0,
        x: 0,
        rotate: 0,
        duration: 2,
        delay: 0.5,
      });
      gsap.timeline().to(m4Ref.current, {
        opacity: 1,
        y: 0,
        x: 0,
        rotate: 0,
        duration: 2,
        delay: 0.5,
      });
      gsap.timeline().to(m5Ref.current, {
        opacity: 1,
        y: 0,
        x: 0,
        rotate: 0,
        delay: 0.5,
        duration: 2,
      });
    }
  }, [width]);

  return (
    <div className={classes.first}>
      <div className={classes.firstBody}>
        <div className="container">
          <div
            className={
              hover
                ? [classes.firstWrapper, classes.hover].join(" ")
                : classes.firstWrapper
            }
          >
            <h1 className={[classes.firstTitle, "font-45"].join(" ")}>
              {t('home_banner_title')}
            </h1>
            <p className={[classes.firstSubtitle, "font-25"].join(" ")}>
              {t('home_banner_subtitle')}
            </p>
            <div className={classes.firstBtn}>
              <Button
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                {t('btn_sign_up_now')}
              </Button>
            </div>
            {width < 1221 && (
              <div className={classes.firstMobImg}>
                <img
                  src={width > 480 ? mob1Img : mob2Img}
                  alt={"Mobile"}
                />
              </div>
            )}

            <div className={classes.firstChart}>
              <img src={chartImg} alt={"Chart"} />
            </div>
            <div className={classes.firstPhone} ref={phoneRef}>
              <img quality={100} src={phoneImg} alt={"Phone "} />
            </div>
            <div className={classes.firstMonet1} ref={m1Ref}>
              <img quality={100} src={m1Img} alt={"Monet 1"} />
            </div>
            <div className={classes.firstMonet2} ref={m2Ref}>
              <img quality={100} src={m2Img} alt={"Monet 2"} />
            </div>
            <div className={classes.firstMonet3} ref={m3Ref}>
              <img quality={100} src={m3Img} alt={"Monet 3"} />
            </div>
            <div className={classes.firstMonet4} ref={m4Ref}>
              <img quality={100} src={m4Img} alt={"Monet 4"} />
            </div>
            <div className={classes.firstMonet5} ref={m5Ref}>
              <img quality={100} src={m5Img} alt={"Monet 5"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
