import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <div className={classes.footer}>
      <div className="container">
        <div className={classes.content}>
          <div className={classes.info}>
            <div className={classes.row}>
              <h5 className={classes.rowTitle}>
                {t('footer_disc_title')}
              </h5>
              <p className={classes.rowText}>
                {t('footer_disc_text_01')}
              </p>
              <p>
                {t('footer_disc_text_02')}
              </p>
            </div>
            <div className={classes.row}>
              <h5 className={classes.rowTitle}>
                {t('footer_about_title')}
              </h5>
              <p className={classes.rowText}>
                {t('footer_about_text_01')}
              </p>
              <p className={classes.rowText}>
                {t('footer_about_text_02')}
              </p>
            </div>

            <div className={classes.row}>
              <p className={classes.rowText}>
                {t('footer_rights')}
              </p>
            </div>
          </div>
          <div className={classes.docs}>
            <div>
              <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                {t('footer_docs_terms')}
              </a>
            </div>
            <div>
              <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                {t('footer_docs_privacy')}
              </a>
            </div>
            <div>
              <a href="/docs/AML_POLICY.pdf" target="_blank">
                {t('footer_docs_aml')}
              </a>
            </div>
            <div>
              <a href="/docs/FCA.pdf" target="_blank">
                FCA
              </a>
            </div>
          </div>
          <div className={classes.about}>
            <p>
              {t('footer_contact_us')}
            </p>
            <div>
              <p>
                {t('footer_contacts')}
              </p>
              <a href="tel:+441863440194">
                +441863440194
              </a>
              <a href={`mailto:support@${hostname}`}>
                support@{hostname}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
