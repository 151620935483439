import React, { useRef, useEffect, useState } from "react";
import classes from './ExpertSupport.module.scss';
import $ from 'jquery';
import { useTranslation } from "react-i18next";

const ExpertSupport = () => {
    const { t } = useTranslation();
    const tab01Ref = useRef(null);
    const tab02Ref = useRef(null);
    const tab03Ref = useRef(null);
    const tab04Ref = useRef(null);
    const tab05Ref = useRef(null);
    const [tab01Active, setTab01Active] = useState(false);
    const [tab02Active, setTab02Active] = useState(false);
    const [tab03Active, setTab03Active] = useState(false);
    const [tab04Active, setTab04Active] = useState(false);
    const [tab05Active, setTab05Active] = useState(false);
    useEffect(() => {
        $(tab01Ref.current).slideUp(0);
        $(tab02Ref.current).slideUp(0);
        $(tab03Ref.current).slideUp(0);
        $(tab04Ref.current).slideUp(0);
        $(tab05Ref.current).slideUp(0);
    }, []);
    return (
        <section className="container">
            <div className={classes.expertSupport}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('accounts_support_title')}
                </h2>
                <p className={[classes.subtitle, 'font-25'].join(' ')}>
                    {t('accounts_support_subtitle')}
                </p>
                <div className={classes.spoilers}>
                    <div
                        className={[classes.tab, 'no-select'].join(' ')}
                        onClick={() => { 
                            $(tab01Ref.current).slideToggle(400); 
                            setTab01Active((value) => !value);
                        }}
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'font-20'].join(' ')}>
                                {t('accounts_support_tab_01_title')}
                            </h3>
                            <div className={`${classes.tabTitleStatus} ${tab01Active && classes.activeStatus}`}></div>
                        </div>
                        <p ref={tab01Ref} className={[classes.tabContent, 'font-18'].join(' ')}>
                            {t('accounts_support_tab_01_text')}
                        </p>
                    </div>
                    <div
                        className={[classes.tab, 'no-select'].join(' ')}
                        onClick={() => { 
                            $(tab02Ref.current).slideToggle(400); 
                            setTab02Active((value) => !value);
                        }}
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'font-20'].join(' ')}>
                                {t('accounts_support_tab_02_title')}
                            </h3>
                            <div className={`${classes.tabTitleStatus} ${tab02Active && classes.activeStatus}`}></div>
                        </div>
                        <p ref={tab02Ref} className={[classes.tabContent, 'font-18'].join(' ')}>
                            {t('accounts_support_tab_02_text')}
                        </p>
                    </div>
                    <div
                        className={[classes.tab, 'no-select'].join(' ')}
                        onClick={() => { 
                            $(tab03Ref.current).slideToggle(400); 
                            setTab03Active((value) => !value);
                        }}
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'font-20'].join(' ')}>
                                {t('accounts_support_tab_03_title')}
                            </h3>
                            <div className={`${classes.tabTitleStatus} ${tab03Active && classes.activeStatus}`}></div>
                        </div>
                        <p ref={tab03Ref} className={[classes.tabContent, 'font-18'].join(' ')}>
                            {t('accounts_support_tab_03_text')}
                        </p>
                    </div>
                    <div
                        className={[classes.tab, 'no-select'].join(' ')}
                        onClick={() => { 
                            $(tab04Ref.current).slideToggle(400); 
                            setTab04Active((value) => !value);
                        }}
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'font-20'].join(' ')}>
                                {t('accounts_support_tab_04_title')}
                            </h3>
                            <div className={`${classes.tabTitleStatus} ${tab04Active && classes.activeStatus}`}></div>
                        </div>
                        <div ref={tab04Ref} className={[classes.tabContent, 'font-18'].join(' ')}>
                            <p>
                                {t('accounts_support_tab_04_text_01')}
                            </p>
                            <p>
                                {t('accounts_support_tab_04_text_02')}
                            </p>
                        </div>
                    </div>
                    <div
                        className={[classes.tab, 'no-select'].join(' ')}
                        onClick={() => { 
                            $(tab05Ref.current).slideToggle(400); 
                            setTab05Active((value) => !value);
                        }}
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'font-20'].join(' ')}>
                                {t('accounts_support_tab_05_title')}
                            </h3>
                            <div className={`${classes.tabTitleStatus} ${tab05Active && classes.activeStatus}`}></div>
                        </div>
                        <div ref={tab05Ref} className={[classes.tabContent, 'font-18'].join(' ')}>
                            <p>
                                {t('accounts_support_tab_05_text_01')}
                            </p>
                            <p>
                                {t('accounts_support_tab_05_text_02')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExpertSupport;
