import React from "react";
import classes from "./Four.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.four, "mb"].join(" ")}>
      <div className={classes.fourBody}>
        <div className="container">
          <h2 className={[classes.fourTitle, "font-45"].join(" ")}>
            {t('home_what_get_title')}
          </h2>
          <div className={classes.fourRow}>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnImg}></div>
              <h3 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                <Trans>
                  {t('home_what_get_card_01_title')}
                </Trans>
              </h3>
              <p className={[classes.fourRowColumnSubtitle, "font-18"].join(" ")}>
                {t('home_what_get_card_01_text')}
              </p>
            </div>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnImg}></div>
              <h3 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                <Trans>
                  {t('home_what_get_card_02_title')}
                </Trans>
              </h3>
              <p className={[classes.fourRowColumnSubtitle, "font-18"].join(" ")}>
                {t('home_what_get_card_02_text')}
              </p>
            </div>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnImg}></div>
              <h3 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                <Trans>
                  {t('home_what_get_card_03_title')}
                </Trans>
              </h3>
              <p className={[classes.fourRowColumnSubtitle, "font-18"].join(" ")}>
                {t('home_what_get_card_03_text')}
              </p>
            </div>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnImg}></div>
              <h3 className={[classes.fourRowColumnTitle, "font-20"].join(" ")}>
                <Trans>
                  {t('home_what_get_card_04_title')}
                </Trans>
              </h3>
              <p className={[classes.fourRowColumnSubtitle, "font-18"].join(" ")}>
                {t('home_what_get_card_04_text')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
