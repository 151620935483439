import React from "react";
import classes from './LandingRates.module.scss';
import { Trans, useTranslation } from "react-i18next";

const LandingRates = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.landingRates}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('products_lending_title')}
                </h2>
                <div className={classes.content}>
                    <div className={classes.col}>
                        <p>
                            {t('products_lending_text_01')}
                        </p>
                        <div className={classes.tableWr}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{t('products_lending_tr_td_01')}</td>
                                        <td>1</td>
                                        <td>2</td>
                                        <td>3</td>
                                        <td>4</td>
                                        <td>5</td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>{t('products_lending_tr_td_02')}</td>
                                        <td>75%</td>
                                        <td>50%</td>
                                        <td>50%</td>
                                        <td>25%</td>
                                        <td>0%</td>
                                        <td>0%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <p>
                            <Trans>
                                {t('products_lending_text_02')}
                            </Trans>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LandingRates;
