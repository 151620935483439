import React from "react";
import classes from "./Button.module.scss";

const Button = ({ login = false, children, nolink = false, white = false }) => {
  if (nolink) {
    return (
      <div className={classes.button}>
        <span className={classes.btnsvg__label}>{children}</span>
      </div>
    );
  }
  return (
    <a 
      href={`https://user.skylar-platform.org/${login ? 'login' : 'signup'}`} 
      className={`${classes.button} ${white && classes.white}`}
    >
      <span className={classes.btnsvg__label}>{children}</span>
    </a>
  );
};

export default Button;
