import React from "react";
import classes from './WeConnect.module.scss';
import brand_google from '../../../assets/img/AboutPage/brands/brand_google.png';
import brand_apple from '../../../assets/img/AboutPage/brands/brand_apple.png';
import brand_amazon from '../../../assets/img/AboutPage/brands/brand_amazon.png';
import { useTranslation } from "react-i18next";

const WeConnect = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.weConnect}>
                <img src={brand_google} className={[classes.brand_google, classes.brand].join(' ')} alt=''/>
                <img src={brand_apple} className={[classes.brand_apple, classes.brand].join(' ')} alt=''/>
                <img src={brand_amazon} className={[classes.brand_amazon, classes.brand].join(' ')} alt=''/>
                <div className={classes.weConnectBody}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('about_connect_title')}
                    </h2>
                    <div className={[classes.content, 'font-20'].join(' ')}>
                        <div className={classes.col}>
                            <div className={classes.block}>
                                <div className={classes.subtitle}>
                                    {t('about_connect_card_01_title')}
                                </div>
                                <div className={classes.text}>
                                    {t('about_connect_card_01_text')}
                                </div>
                            </div>
                            <div className={classes.block}>
                                <div className={classes.subtitle}>
                                    {t('about_connect_card_02_title')}
                                </div>
                                <div className={classes.text}>
                                    {t('about_connect_card_02_text')}
                                </div>
                            </div>
                        </div>
                        <div className={classes.col}>
                            <div className={classes.block}>
                                <div className={classes.subtitle}>
                                    {t('about_connect_card_03_title')}
                                </div>
                                <div className={classes.text}>
                                    {t('about_connect_card_03_text')}
                                </div>
                            </div>
                            <div className={classes.block}>
                                <div className={classes.subtitle}>
                                    {t('about_connect_card_04_title')}
                                </div>
                                <div className={classes.text}>
                                    {t('about_connect_card_04_text')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WeConnect;
