import { useEffect, useState, useRef } from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/AboutPage/Banner/Banner";
import WeConnect from "../../components/AboutPage/WeConnect/WeConnect";
import OurTop from "../../components/AboutPage/OurTop/OurTop";
import TradesPartners from "../../components/AboutPage/TradesPartners/TradesPartners";
import WorkingTogether from "../../components/AboutPage/WorkingTogether/WorkingTogether";
import MarketsNever from "../../components/AboutPage/MarketsNever/MarketsNever";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutPage = () => {
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const col2 = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
    }
  }, [width]);
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <div className="anim-block" ref={col1}>
        <WeConnect />
      </div>
      <div className="anim-block" ref={col2}>
        <OurTop />
      </div>
      <TradesPartners />
      <WorkingTogether />
      <MarketsNever />
      <Footer />
    </div>
  );
};

export default AboutPage;
