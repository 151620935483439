import React from "react";
import img1 from "../../../assets/img/main/3/1.png";
import classes from "./Five.module.scss";
import { useTranslation } from "react-i18next";

const Five = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.five, "mb"].join(" ")}>
      <div className={classes.fiveBody}>
        <div className="container">
          <h2 className={[classes.fiveTitle, "font-45"].join(" ")}>
            {t('home_self_inv_title')}
          </h2>
          <div className={classes.fiveRow}>
            <div className={classes.fiveRowLeft}>
              <img src={img1} alt={"SELF-GOVERNED INVESTING"} />
            </div>
            <div className={classes.fiveRowRight}>
              <h3 className={[classes.fiveRowRightTitle, "font-20"].join(" ")}>
                {t('home_self_inv_subtitle')}
              </h3>
              <p className={[classes.fiveRowRightText, "font-20"].join(" ")}>
                {t('home_self_inv_text')}
              </p>
              <div className={classes.fiveRowRightRow}>
                <ul className={classes.fiveRowRightRowList}>
                  <li className={[classes.fiveRowRightRowListItem, "font-20"].join(" ")}>
                    • {t('home_self_inv_li_01')}
                  </li>
                  <li className={[classes.fiveRowRightRowListItem, "font-20"].join(" ")}>
                    • {t('home_self_inv_li_02')}
                  </li>
                  <li className={[classes.fiveRowRightRowListItem, "font-20"].join(" ")}>
                    • {t('home_self_inv_li_03')}
                  </li>
                </ul>
                <ul className={classes.fiveRowRightRowList}>
                  <li className={[classes.fiveRowRightRowListItem, "font-20"].join(" ")}>
                    • {t('home_self_inv_li_04')}
                  </li>
                  <li className={[classes.fiveRowRightRowListItem, "font-20"].join(" ")}>
                    • {t('home_self_inv_li_05')}
                  </li>
                  <li className={[classes.fiveRowRightRowListItem, "font-20"].join(" ")}>
                    • {t('home_self_inv_li_06')}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
