import { useEffect, useState, useRef } from "react";
import classes from "./Three.module.scss";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Three = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const line1 = useRef(null);
  const col2 = useRef(null);
  const line2 = useRef(null);
  const firstRef = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width > 992) {
      let bottomAnimation = gsap.timeline({
        delay: 1,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top bottom",
          end: "center center",
          scrub: 5,
          id: "scrub",
        },
      });

      bottomAnimation
        .to(line1.current, {
          height: "100%",
        })
        .to(line2.current, {
          height: "100%",
        });
    }
  }, [width]);
  return (
    <div className={[classes.three, "mt mb"].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.threeTitle, "font-45"].join(" ")}>
            {t('education_steps_title')}
          </h2>
          <div className={[classes.threeWrapper, ""].join(" ")} ref={firstRef}>
            <div className={[classes.threeRow, ""].join(" ")} ref={col1}>
              <div
                className={[classes.threeRowLeft, classes.opacity, ""].join(
                  " "
                )}
              ></div>
              <div className={[classes.threeRowCenter, ""].join(" ")}>
                <div className={[classes.threeRowNumber, ""].join(" ")}>1</div>
              </div>
              <div className={[classes.threeRowRight, ""].join(" ")}>
                <h3 className={[classes.threeRowTitle, "font-20"].join(" ")}>
                  {t('education_steps_card_01_title')}
                </h3>
                <p
                  className={[classes.threeRowRightSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('education_steps_card_01_text')}
                </p>
              </div>
              <div className={[classes.threeLine, ""].join(" ")}>
                <span ref={line1}></span>
              </div>
            </div>
            <div className={[classes.threeRow, ""].join(" ")} ref={col2}>
              <div className={[classes.threeLine, ""].join(" ")}>
                <span ref={line2}></span>
              </div>
              <div className={[classes.threeRowLeft, ""].join(" ")}>
                <h3 className={[classes.threeRowTitle, "font-20"].join(" ")}>
                  {t('education_steps_card_02_title')}
                </h3>
                <p
                  className={[classes.threeRowRightSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('education_steps_card_02_text')}
                </p>
              </div>
              <div className={[classes.threeRowCenter, ""].join(" ")}>
                <div className={[classes.threeRowNumber, ""].join(" ")}>2</div>
              </div>
              <div
                className={[classes.threeRowRight, classes.opacity, ""].join(
                  " "
                )}
              ></div>
            </div>
            <div className={[classes.threeRow, ""].join(" ")}>
              <div className={[classes.threeLine, ""].join(" ")}>
                <span></span>
              </div>
              <div
                className={[classes.threeRowLeft, classes.opacity, ""].join(
                  " "
                )}
              ></div>
              <div className={[classes.threeRowCenter, ""].join(" ")}>
                <div className={[classes.threeRowNumber, ""].join(" ")}>3</div>
              </div>
              <div className={[classes.threeRowRight, ""].join(" ")}>
                <h3 className={[classes.threeRowTitle, "font-20"].join(" ")}>
                  {t('education_steps_card_03_title')}
                </h3>
                <p
                  className={[classes.threeRowRightSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('education_steps_card_03_text')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
