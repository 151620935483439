import React, { useEffect } from "react";
import classes from './ExpertSupport.module.scss';
import icon_01 from '../../../assets/img/ProductsPage/ExpertSupport/icon_01.png';
import icon_02 from '../../../assets/img/ProductsPage/ExpertSupport/icon_02.png';
import icon_03 from '../../../assets/img/ProductsPage/ExpertSupport/icon_03.png';
import icon_04 from '../../../assets/img/ProductsPage/ExpertSupport/icon_04.png';
import icon_h_01 from '../../../assets/img/ProductsPage/ExpertSupport/icon_h_01.png';
import icon_h_02 from '../../../assets/img/ProductsPage/ExpertSupport/icon_h_02.png';
import icon_h_03 from '../../../assets/img/ProductsPage/ExpertSupport/icon_h_03.png';
import icon_h_04 from '../../../assets/img/ProductsPage/ExpertSupport/icon_h_04.png';
import { Trans, useTranslation } from "react-i18next";

const ExpertSupport = () => {
    const { t } = useTranslation();
    function handleMouseover(e) {
        document.querySelector(`.${classes.active}`).classList.remove(`${classes.active}`);
        e.target.closest(`.${classes.card}`).classList.add(`${classes.active}`);
    }
    useEffect(() => {
        document.querySelectorAll(`.${classes.card}`).forEach(card => card.addEventListener("mouseover", handleMouseover) );
    }, []);
    return (
        <section className="container">
            <div className={classes.expertSupport}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('products_support_title')}
                </h2>
                <h4 className={[classes.subtitle, 'font-25'].join(' ')}>
                    {t('products_support_subtitle')}
                </h4>
                <div className={classes.cards}>
                    <div className={[classes.card, classes.active].join(' ')}>
                        <svg className={classes.svgBackground} viewBox="0 0 600 600">
                            <path className={classes.blob} d="M192.1,-148.2C230,-105.8,228.6,-27,204.4,33.7C180.2,94.3,133.3,136.8,77.7,165C22.1,193.1,-42.1,206.9,-94.5,185.7C-146.9,164.5,-187.5,108.3,-201.7,46C-215.9,-16.2,-203.7,-84.5,-165.7,-127C-127.7,-169.5,-63.8,-186.3,6.6,-191.6C77.1,-196.9,154.3,-190.7,192.1,-148.2Z"></path>
                        </svg>
                        <div className={classes.cardIcon}>
                            <img src={icon_01} className={classes.cardIconDefault} alt=''/>
                            <img src={icon_h_01} className={classes.cardIconHover} alt=''/>
                        </div>
                        <h4 className={classes.cardTitle}>
                            <Trans>
                                {t('products_support_card_01_title')}
                            </Trans>
                        </h4>
                        <p className={classes.cardText}>
                            {t('products_support_card_01_text')}
                        </p>
                    </div>
                    <div className={classes.card}>
                        <svg className={classes.svgBackground} viewBox="0 0 600 600">
                            <path className={classes.blob} d="M186.5,-139.7C230.7,-93.3,247.9,-16.4,233.4,55.7C218.9,127.7,172.6,195,112.8,217.3C52.9,239.7,-20.4,217.1,-84.7,183.3C-148.9,149.5,-204.1,104.3,-219.6,47.1C-235.2,-10.2,-211,-79.6,-167.8,-125.8C-124.6,-172,-62.3,-195,4.4,-198.5C71.1,-202.1,142.3,-186.1,186.5,-139.7Z" fill="#FE840E" />
                        </svg>
                        <div className={classes.cardIcon}>
                            <img src={icon_02} className={classes.cardIconDefault} alt=''/>
                            <img src={icon_h_02} className={classes.cardIconHover} alt=''/>
                        </div>
                        <h4 className={classes.cardTitle}>
                            <Trans>
                                {t('products_support_card_02_title')}
                            </Trans>
                        </h4>
                        <p className={classes.cardText}>
                            {t('products_support_card_02_text')}
                        </p>
                    </div>
                    <div className={classes.card}>
                        <svg className={classes.svgBackground} viewBox="0 0 600 600">
                            <path className={classes.blob} d="M186.5,-139.7C230.7,-93.3,247.9,-16.4,233.4,55.7C218.9,127.7,172.6,195,112.8,217.3C52.9,239.7,-20.4,217.1,-84.7,183.3C-148.9,149.5,-204.1,104.3,-219.6,47.1C-235.2,-10.2,-211,-79.6,-167.8,-125.8C-124.6,-172,-62.3,-195,4.4,-198.5C71.1,-202.1,142.3,-186.1,186.5,-139.7Z" fill="#FE840E" />
                        </svg>
                        <div className={classes.cardIcon}>
                            <img src={icon_03} className={classes.cardIconDefault} alt=''/>
                            <img src={icon_h_03} className={classes.cardIconHover} alt=''/>
                        </div>
                        <h4 className={classes.cardTitle}>
                            <Trans>
                                {t('products_support_card_03_title')}
                            </Trans>
                        </h4>
                        <p className={classes.cardText}>
                            {t('products_support_card_03_text')}
                        </p>
                    </div>
                    <div className={classes.card}>
                        <svg className={classes.svgBackground} viewBox="0 0 600 600">
                            <path className={classes.blob} d="M192.1,-148.2C230,-105.8,228.6,-27,204.4,33.7C180.2,94.3,133.3,136.8,77.7,165C22.1,193.1,-42.1,206.9,-94.5,185.7C-146.9,164.5,-187.5,108.3,-201.7,46C-215.9,-16.2,-203.7,-84.5,-165.7,-127C-127.7,-169.5,-63.8,-186.3,6.6,-191.6C77.1,-196.9,154.3,-190.7,192.1,-148.2Z"></path>
                        </svg>
                        <div className={classes.cardIcon}>
                            <img src={icon_04} className={classes.cardIconDefault} alt=''/>
                            <img src={icon_h_04} className={classes.cardIconHover} alt=''/>
                        </div>
                        <h4 className={classes.cardTitle}>
                            <Trans>
                                {t('products_support_card_04_title')}
                            </Trans>
                        </h4>
                        <p className={classes.cardText}>
                            {t('products_support_card_04_text')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExpertSupport;
