import logo from '../assets/img/logo.png';
import img1 from '../assets/img/main/1/1.png'
import img2 from '../assets/img/main/1/2.png'
import img3 from '../assets/img/main/1/m1.png'
import img4 from '../assets/img/main/1/m2.png'
import img5 from '../assets/img/main/1/m3.png'
import img6 from '../assets/img/main/1/m4.png'
import img7 from '../assets/img/main/1/m5.png'
import img8 from '../assets/img/main/1/mob2.png'


export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
];
