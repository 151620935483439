import React from "react";
import classes from "./Second.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.second}>
      <div className={classes.secondBody}>
        <div className="container">
          <div className={classes.secondRow}>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnItem}>
                <h3 className={[classes.secondRowColumnItemTitle, "font-30"].join(" ")}>
                  {t('home_underbanner_li_01_title')}
                </h3>
                <p className={[classes.secondRowColumnItemText, "font-20"].join(" ")}>
                  {t('home_underbanner_li_01_text')}
                </p>
              </div>
            </div>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnItem}>
                <h3 className={[classes.secondRowColumnItemTitle, "font-30"].join(" ")}>
                  {t('home_underbanner_li_02_title')}
                </h3>
                <p className={[classes.secondRowColumnItemText, "font-20"].join(" ")}>
                  {t('home_underbanner_li_02_text')}
                </p>
              </div>
            </div>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnItem}>
                <h3 className={[classes.secondRowColumnItemTitle, "font-30"].join(" ")}>
                  {t('home_underbanner_li_03_title')}
                </h3>
                <p className={[classes.secondRowColumnItemText, "font-20"].join(" ")}>
                  <Trans>
                    {t('home_underbanner_li_03_text')}
                  </Trans>
                </p>
              </div>
            </div>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnItem}>
                <h3 className={[classes.secondRowColumnItemTitle, "font-30"].join(" ")}>
                  {t('home_underbanner_li_04_title')}
                </h3>
                <p className={[classes.secondRowColumnItemText, "font-20"].join(" ")}>
                  <Trans>
                    {t('home_underbanner_li_04_text')}
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
