import React, { useState, useRef } from "react";
import classes from "./Six.module.scss";
import $ from "jquery";
import img1 from "../../../assets/img/education/4/1.png";
import { useTranslation } from "react-i18next";

const Six = () => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  return (
    <div className={[classes.six, "no-select"].join(" ")}>
      <div className={[classes.sixBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.sixRow, ""].join(" ")}>
            <div className={[classes.sixRowLeft, ""].join(" ")}>
              <h2 className={[classes.sixTitle, "font-45"].join(" ")}>
                {t('education_adv_title')}
              </h2>

              <div
                className={
                  count === 1
                    ? [classes.sixRowLeftTab, classes.active, ""].join(" ")
                    : [classes.sixRowLeftTab, ""].join(" ")
                }
              >
                <h3
                  onClick={() => {
                    if (count === 1) {
                      setCount(0);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    } else {
                      setCount(1);
                      $(ref1.current).slideDown(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    }
                  }}
                  className={[classes.sixRowLeftTabTitle, "font-20"].join(" ")}
                >
                  {t('education_adv_card_01_title')}
                </h3>
                <div
                  className={[classes.sixRowLeftTabContent, ""].join(" ")}
                  ref={ref1}
                >
                  <p
                    className={[
                      classes.sixRowLeftTabContentSubtitle,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('education_adv_card_01_text_01')}
                  </p>
                  <p
                    className={[
                      classes.sixRowLeftTabContentSubtitle,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('education_adv_card_01_text_02')}
                  </p>
                </div>
              </div>
              <div
                className={
                  count === 2
                    ? [classes.sixRowLeftTab, classes.active, ""].join(" ")
                    : [classes.sixRowLeftTab, ""].join(" ")
                }
              >
                <h3
                  className={[classes.sixRowLeftTabTitle, "font-20"].join(" ")}
                  onClick={() => {
                    if (count === 2) {
                      setCount(0);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    } else {
                      setCount(2);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideDown(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    }
                  }}
                >
                  {t('education_adv_card_02_title')}
                </h3>
                <div
                  className={[classes.sixRowLeftTabContent, ""].join(" ")}
                  ref={ref2}
                >
                  <p
                    className={[
                      classes.sixRowLeftTabContentSubtitle,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('education_adv_card_02_text')}
                  </p>
                </div>
              </div>
              <div
                className={
                  count === 3
                    ? [classes.sixRowLeftTab, classes.active, ""].join(" ")
                    : [classes.sixRowLeftTab, ""].join(" ")
                }
              >
                <h3
                  className={[classes.sixRowLeftTabTitle, "font-20"].join(" ")}
                  onClick={() => {
                    if (count === 3) {
                      setCount(0);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    } else {
                      setCount(3);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideDown(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    }
                  }}
                >
                  {t('education_adv_card_03_title')}
                </h3>
                <div
                  className={[classes.sixRowLeftTabContent, ""].join(" ")}
                  ref={ref3}
                >
                  <p
                    className={[
                      classes.sixRowLeftTabContentSubtitle,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('education_adv_card_03_text')}
                  </p>
                </div>
              </div>
              <div
                className={
                  count === 4
                    ? [classes.sixRowLeftTab, classes.active, ""].join(" ")
                    : [classes.sixRowLeftTab, ""].join(" ")
                }
              >
                <h3
                  className={[classes.sixRowLeftTabTitle, "font-20"].join(" ")}
                  onClick={() => {
                    if (count === 4) {
                      setCount(0);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideUp(300);
                      $(ref5.current).slideUp(300);
                    } else {
                      setCount(4);
                      $(ref1.current).slideUp(300);
                      $(ref2.current).slideUp(300);
                      $(ref3.current).slideUp(300);
                      $(ref4.current).slideDown(300);
                      $(ref5.current).slideUp(300);
                    }
                  }}
                >
                  {t('education_adv_card_04_title')}
                </h3>
                <div
                  className={[classes.sixRowLeftTabContent, ""].join(" ")}
                  ref={ref4}
                >
                  <p
                    className={[
                      classes.sixRowLeftTabContentSubtitle,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('education_adv_card_04_text')}
                  </p>
                </div>
              </div>
            </div>
            <div className={[classes.sixRowRight, ""].join(" ")}>
              <img
                quality={100}
                src={img1}
                alt="Advantages of Trading CFDs"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Six;
