import { useEffect, useState, useRef } from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import First from "../../components/MainComponents/First/First";
import Second from "../../components/MainComponents/Second/Second";
import Three from "../../components/MainComponents/Three/Three";
import Four from "../../components/MainComponents/Four/Four";
import Five from "../../components/MainComponents/Five/Five";
import Six from "../../components/MainComponents/Six/Six";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Homepage = () => {
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top bottom",
          end: "top center",
          scrub: 4,
          id: "scrub",
          once: true,
        },
      });
    }
  }, [width]);
  return (
    <div className="ovf-hidden">
      <Header />
      <First />
      <Second />
      <div className="anim-block" ref={col1}>
        <Three />
      </div>
      <div className="anim-block" ref={col2}>
        <Four />
      </div>
      <div className="anim-block" ref={col3}>
        <Five />
      </div>
      <div className="anim-block" ref={col4}>
        <Six />
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
