import React from "react";
import classes from './Banner.module.scss';
import circle from '../../../assets/img/AboutPage/Banner/circle.png';
import image from '../../../assets/img/AboutPage/Banner/image.png';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <h1 className={[classes.title, 'font-45'].join(' ')}>
                    {t('about_banner_title')}
                </h1>
                <div className={[classes.text, 'font-20'].join(' ')}>
                    <p>
                        {t('about_banner_text_01')}
                    </p>
                    <p>
                        {t('about_banner_text_02')}
                    </p>
                </div>
                <div className={classes.btn}>
                    <Button>
                        {t('btn_open_account')}
                    </Button>
                </div>
                <img className={classes.image} src={image} alt=''/>
                <img className={classes.circle} src={circle} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
