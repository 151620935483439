import React, { useEffect, useRef } from "react";
import classes from './Prices.module.scss';
import coin from '../../../assets/img/ProductsPage/Prices/coin.png';
import gsap from 'gsap';
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Prices = () => {
    const { t } = useTranslation();
    const pricesCoinRef = useRef(null);
    const pricesCoinWrRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(pricesCoinRef.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: pricesCoinWrRef.current,
                        end: "+=300px",
                        scrub: 4,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.prices}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('products_prices_title')}
                </h2>
                <div ref={pricesCoinWrRef} className={classes.coin}>
                    <img ref={pricesCoinRef} src={coin} alt=''/>
                </div>
                <div className={classes.tableWr}>
                    <table>
                        <tbody>
                            <tr>
                                <td>{t('products_prices_tr_01_td_01')}</td>
                                <td>{t('products_prices_tr_01_td_02')}</td>
                            </tr>
                            <tr>
                                <td>{t('products_prices_tr_02_td_01')}</td>
                                <td>{t('products_prices_tr_td_from')} $1</td>
                            </tr>
                            <tr>
                                <td>{t('products_prices_tr_03_td_01')}</td>
                                <td>{t('products_prices_tr_td_from')} $1</td>
                            </tr>
                            <tr>
                                <td>{t('products_prices_tr_04_td_01')}</td>
                                <td>{t('products_prices_tr_td_from')} $1.25</td>
                            </tr>
                            <tr>
                                <td>{t('products_prices_tr_05_td_01')}</td>
                                <td>{t('products_prices_tr_td_from')} 0.05%</td>
                            </tr>
                            <tr>
                                <td>{t('products_prices_tr_06_td_01')}</td>
                                <td>0%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default Prices;
