import React, { useEffect, useRef } from "react";
import classes from './ProductsFor.module.scss';
import icon_01 from '../../../assets/img/ProductsPage/ProductsFor/icon_01.png';
import icon_02 from '../../../assets/img/ProductsPage/ProductsFor/icon_02.png';
import icon_03 from '../../../assets/img/ProductsPage/ProductsFor/icon_03.png';
import icon_04 from '../../../assets/img/ProductsPage/ProductsFor/icon_04.png';
import icon_05 from '../../../assets/img/ProductsPage/ProductsFor/icon_05.png';
import icon_06 from '../../../assets/img/ProductsPage/ProductsFor/icon_06.png';
import icon_07 from '../../../assets/img/ProductsPage/ProductsFor/icon_07.png';
import icon_08 from '../../../assets/img/ProductsPage/ProductsFor/icon_08.png';
import icon_09 from '../../../assets/img/ProductsPage/ProductsFor/icon_09.png';
import icon_h_01 from '../../../assets/img/ProductsPage/ProductsFor/icon_h_01.png';
import icon_h_02 from '../../../assets/img/ProductsPage/ProductsFor/icon_h_02.png';
import icon_h_03 from '../../../assets/img/ProductsPage/ProductsFor/icon_h_03.png';
import icon_h_04 from '../../../assets/img/ProductsPage/ProductsFor/icon_h_04.png';
import icon_h_05 from '../../../assets/img/ProductsPage/ProductsFor/icon_h_05.png';
import icon_h_06 from '../../../assets/img/ProductsPage/ProductsFor/icon_h_06.png';
import icon_h_07 from '../../../assets/img/ProductsPage/ProductsFor/icon_h_07.png';
import icon_h_08 from '../../../assets/img/ProductsPage/ProductsFor/icon_h_08.png';
import icon_h_09 from '../../../assets/img/ProductsPage/ProductsFor/icon_h_09.png';
import coin_eth from '../../../assets/img/ProductsPage/ProductsFor/coin_eth.png';
import coin_btc from '../../../assets/img/ProductsPage/ProductsFor/coin_btc.png';
import arrow from '../../../assets/img/ProductsPage/ProductsFor/arrow.gif';
import Button from "../../GlobalComponents/Button/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import gsap from 'gsap';
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const slides = [
    {
        icon: icon_01,
        icon_h: icon_h_01,
        title: 'products_self_tr_card_01_title',
        text: 'products_self_tr_card_01_text',
    },
    {
        icon: icon_02,
        icon_h: icon_h_02,
        title: 'products_self_tr_card_02_title',
        text: 'products_self_tr_card_02_text',
    },
    {
        icon: icon_03,
        icon_h: icon_h_03,
        title: 'products_self_tr_card_03_title',
        text: 'products_self_tr_card_03_text',
    },
    {
        icon: icon_04,
        icon_h: icon_h_04,
        title: 'products_self_tr_card_04_title',
        text: 'products_self_tr_card_04_text',
    },
    {
        icon: icon_05,
        icon_h: icon_h_05,
        title: 'products_self_tr_card_05_title',
        text: 'products_self_tr_card_05_text',
    },
    {
        icon: icon_06,
        icon_h: icon_h_06,
        title: 'products_self_tr_card_06_title',
        text: 'products_self_tr_card_06_text',
    },
    {
        icon: icon_07,
        icon_h: icon_h_07,
        title: 'products_self_tr_card_07_title',
        text: 'products_self_tr_card_07_text',
    },
    {
        icon: icon_08,
        icon_h: icon_h_08,
        title: 'products_self_tr_card_08_title',
        text: 'products_self_tr_card_08_text',
    },
    {
        icon: icon_09,
        icon_h: icon_h_09,
        title: 'products_self_tr_card_09_title',
        text: 'products_self_tr_card_09_text',
    },
];

const ProductsFor = () => {
    const { t } = useTranslation();
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const productsForEthRef = useRef(null);
    const productsForEthWrRef = useRef(null);
    const productsForBtcRef = useRef(null);
    const productsForBtcWrRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(productsForEthRef.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: productsForEthWrRef.current,
                        end: "+=200px",
                        scrub: 4,
                    }
                });
                gsap.timeline().fromTo(productsForBtcRef.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: productsForBtcWrRef.current,
                        end: "+=300px",
                        scrub: 4,
                    }
                });
            }
        }
    }, []);
    return (
        <section>
            <div className={classes.productsFor}>
                <div className="container">
                    <h2 className={[classes.title, 'font-45'].join(' ')}>
                        {t('products_self_tr_title')}
                    </h2>
                    <div className={classes.images}>
                        <div ref={productsForEthWrRef} className={classes.coin_eth}>
                            <img ref={productsForEthRef} src={coin_eth} alt=''/>
                        </div>
                        <div ref={productsForBtcWrRef} className={classes.coin_btc}>
                            <img ref={productsForBtcRef} src={coin_btc} alt=''/>
                        </div>
                    </div>
                </div>
                <div className={classes.swiperWr}>
                    <Swiper 
                        modules={[Pagination, Navigation]}
                        className="productsForSwiper"
                        spaceBetween={40}
                        slidesPerView={1}
                        loop={true}
                        pagination={{ clickable: true }}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                        }}
                    >
                        {slides.map((slide, index) =>
                            <SwiperSlide key={index} className="productsForSwiperSlide">
                                <div className={classes.slide}>
                                    <img className={classes.slideIcon} src={slide.icon} alt=''/>
                                    <img className={classes.slideIconHover} src={slide.icon_h} alt=''/>
                                    <h4 className={classes.slideTitle}>
                                        {t(slide.title)}
                                    </h4>
                                    <p className={classes.slideText}>
                                        {t(slide.text)}
                                    </p>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <div ref={prevRef} className={[classes.pag, classes.pagLeft].join(' ')}>
                        <img src={arrow} alt=''/>
                    </div>
                    <div ref={nextRef} className={[classes.pag, classes.pagRight].join(' ')}>
                        <img src={arrow} alt=''/>
                    </div>
                </div>
                <div className="container">
                    <div className={classes.btn}>
                        <Button>
                            {t('btn_start_now')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductsFor;
