import React, { useEffect, useRef } from "react";
import classes from './TradesPartners.module.scss';
import card_01 from '../../../assets/img/AboutPage/TradesPartners/card_01.png';
import card_02 from '../../../assets/img/AboutPage/TradesPartners/card_02.png';
import card_03 from '../../../assets/img/AboutPage/TradesPartners/card_03.png';
import chart from '../../../assets/img/AboutPage/TradesPartners/chart.png';
import together from '../../../assets/img/AboutPage/TradesPartners/together.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const TradesPartners = () => {
    const { t } = useTranslation();
    const tradesPartnersCardRef_01 = useRef(null);
    const tradesPartnersCardRef_02 = useRef(null);
    const tradesPartnersCardRef_03 = useRef(null);
    const tradesPartnersBodyRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(tradesPartnersCardRef_01.current, {
                    y: 50,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: tradesPartnersBodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(tradesPartnersCardRef_02.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: tradesPartnersBodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(tradesPartnersCardRef_03.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: tradesPartnersBodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="container">
            <div ref={tradesPartnersBodyRef} className={classes.tradesPartners}>
                <div className={classes.tradesPartnersBody}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('about_partners_title')}
                    </h2>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        {t('about_partners_text')}
                    </p>
                </div>
                <img className={classes.chart} src={chart} alt=''/>
                <img ref={tradesPartnersCardRef_01} className={classes.card_01} src={card_01} alt=''/>
                <img ref={tradesPartnersCardRef_02} className={classes.card_02} src={card_02} alt=''/>
                <img ref={tradesPartnersCardRef_03} className={classes.card_03} src={card_03} alt=''/>
                <img className={classes.together} src={together} alt=''/>
            </div>
        </section>
    );
};

export default TradesPartners;
